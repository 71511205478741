import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISharedToken } from "@interfaces";
import { RootState } from ".";
import { toursApi } from "@api";

// Async thunk to fetch the shared token
export const fetchSharedToken = createAsyncThunk<ISharedToken, void>(
    "auth/fetchSharedToken",
    async (_, { rejectWithValue }) => {
        try {
            const res = await toursApi.sharedToken();
            return res.data as ISharedToken;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || "Error fetching shared token");
        }
    },
);

interface IState {
    sharedToken: ISharedToken | null;
    isLoading: boolean;
    error: string | null;
    tokenInfoSharedToken: string;
}

const initialState: IState = {
    sharedToken: null,
    isLoading: false,
    error: null,
    tokenInfoSharedToken: "",
};

// Slice for shared token state management
const sharedTokenSlice = createSlice({
    name: "sharedToken",
    initialState,
    reducers: {
        clearSharedToken: () => {
            return initialState;
        },
        setTokenInfoSharedToken: (state, action: PayloadAction<string>) => {
            state.tokenInfoSharedToken = action.payload;
        },
        setSharedToken: (state, action: PayloadAction<ISharedToken>) => {
                state.sharedToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSharedToken.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSharedToken.fulfilled, (state, action: PayloadAction<ISharedToken>) => {
                state.sharedToken = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchSharedToken.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload || "Failed to fetch shared token";
                state.isLoading = false;
            });
    },
});

// Selectors for accessing shared token state
export const selectSharedTokenState = (state: RootState) => state.sharedToken.sharedToken;
export const selectIsLoading = (state: RootState) => state.sharedToken.isLoading;

// Export actions
export const { clearSharedToken, setTokenInfoSharedToken, setSharedToken } =
    sharedTokenSlice.actions;

// Export reducer
export default sharedTokenSlice.reducer;
