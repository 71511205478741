// import { NavLink } from "react-router-dom";

import { InputCommon, StyledButton, StyledForm } from "@components";
import { ISignUpEmail } from "@interfaces";
import { IHandleChange, IHandleBlur, IHandleSubmit } from "@interfaces";
import { Divider } from "@mui/material";
import styled from "styled-components";
import GoogleLoginButton from "../GoogleLoginButton";
import CustomAppleSignIn from "../AppleLoginButton";
// import { PATH_FORGOT_PASSWORD } from "@routes";

interface SignUpEmailProps {
    values: ISignUpEmail;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
}

export const SignUpEmailComponent = (props: SignUpEmailProps) => {
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting } = props;

    return (
        <StyledForm onSubmit={handleSubmit}>
            <StyledWrapper>
                <h2 className="heading">Sign Up</h2>
            </StyledWrapper>
            <InputCommon
                name="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                label="Email"
                type="text"
            />
            <StyledButton
                borderRadius="4px"
                color={["#436CB2 0%", "#28B7C3 100%"]}
                disabled={isSubmitting}
                type="submit"
            >
                Continue
            </StyledButton>
            <Divider
                style={{
                    color: "#898D8F",
                    marginTop: "22px",
                    marginBottom: "2px",
                    fontWeight: "bold",
                    fontSize: "12px",
                }}
            >
                OR
            </Divider>
            <CustomAppleSignIn />
            <GoogleLoginButton />
        </StyledForm>
    );
};

const StyledWrapper = styled(StyledForm)`
    .heading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
    }
`;
