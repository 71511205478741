import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { CheckBoxComponent, StyledButton, StyledChecker } from "@components";
import { useAppSelector, selectSignIn } from "@redux";
import { breakpoint } from "@utilities";
import { PATH_LOGIN } from "@routes";

export const Term = () => {
    // page redux
    const { signIn } = useAppSelector(selectSignIn);
    const navigate = useNavigate();
    // page state
    const [isChecked, setIsChecked] = useState<boolean>(false);
    // page variable
    const signInToken = signIn?.data.accessToken;

    useEffect(() => {
        if (!signInToken) {
            navigate(PATH_LOGIN);
        }
    }, []);

    const handleToggleCheck = () => {
        setIsChecked(!isChecked);
    };

    const handleAccept = () => {
        //     if (isChecked) {
        //         const emailLs = lsEmail.getItem() || [];
        //         emailLs.push(email);
        //         lsEmail.setItem(emailLs);
        //         navigate(PATH_HOME);
        //     }
    };

    return (
        <StyledTerm>
            <h2 className="heading">Term and Conditions</h2>
            <p className="subHeading">
                Before you can proceed, please read and accept the following terms and conditions
            </p>
            <div className="termContent">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco
                est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco
                est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco
                est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco
                est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco
                est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit mollit. Amet minim mollit non deserunt ullamco est sit
                aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
                ullamco est sit aliqua dolor do amet sint. Amet minim mollit non deserunt ullamco
                est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
            </div>
            <div className="buttonWrapper">
                <label htmlFor="termCheckbox" className="checkBox">
                    <StyledChecker>
                        <CheckBoxComponent
                            isChecked={isChecked}
                            onChange={handleToggleCheck}
                            id="termCheckbox"
                        />
                    </StyledChecker>
                    <p className="checkBoxText">
                        I have read, understood, and agree to all of the terms and conditions
                    </p>
                </label>
                <div className="listBtn">
                    {/* <StyledErrorButtonTerm>Decline</StyledErrorButtonTerm> */}
                    <StyledButtonTerm onClick={handleAccept}>Accept</StyledButtonTerm>
                </div>
            </div>
        </StyledTerm>
    );
};

const StyledTerm = styled.div`
    margin-bottom: -28px;
    /* max-width: 295px; */

    .heading {
        margin-top: -18px;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 8px;
        text-align: left;
    }

    .subHeading {
        margin-top: 0;
        margin-bottom: 24px;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.5rem;
        color: ${(props) => props.theme.colors.gray_1};
    }

    .termContent {
        height: 255px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
        overflow-y: scroll;
        padding: 20px;
        text-align: left;
        background-color: #f6f6f6;
        color: ${(p) => p.theme.colors.gray_2};
        @media (max-width: ${(p) => p.theme.breakPoints.breakSmall}) {
            height: 180px;
        }
    }

    .buttonWrapper {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .checkBox {
            display: flex;
            height: 16px;
            margin-right: 12px;
            line-height: 1.5rem;
            ${breakpoint.breakTablet`
            height: 22px;
            `}
            ${breakpoint.breakBig`
            height: 16px;
            `}
            .checkBoxText {
                margin: 0;
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                color: ${(props) => props.theme.colors.gray_1};
            }
        }

        .listBtn {
            display: flex;

            button {
                font-family: DM Sans;
            }
        }
    }

    ${breakpoint.breakMobileMedium`
        .termContent {
            height: 400px;
            text-align: left;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: #F6F6F6;
            padding: 34px;
            font-size: 16px;
            font-weight: 400;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                appearance: none;
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                appearance: none;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                -webkit-appearance: none;
                appearance: none;
                background-color: rgba(0, 0, 0, .3);
                transition: .3s;
                border-radius: 5px;
            }
        }

        .buttonWrapper {
            flex-direction: row;
            justify-content: space-between;

            .PrivateSwitchBase-root-1 {
                padding: 0 12px 0 0;
            }
        }
    `}

    ${breakpoint.breakLarge`
        max-width: 920px;
    `}
    .MuiIconButton-label {
        position: relative;
        top: 2px;
    }

    @media (max-width: ${(p) => p.theme.breakPoints.breakMobile}) {
        .heading {
            font-size: 24px;
        }
        .subHeadingm,
        .termContent,
        .checkBoxText {
            font-size: 12px;
        }
    }
`;

// const StyledErrorButtonTerm = styled(StyledErrorButton)`
//     margin-right: 16px;

//     ${breakpoint.breakMobileMedium`
//         height: 35px;
//         width: 85px;
//         margin-top: 0;
//     `}

//     ${breakpoint.breakLarge`
//         height: 54px;
//         width: 160px;
//     `}
// `;

const StyledButtonTerm = styled(StyledButton)`
    ${breakpoint.breakMobileMedium`
        height: 35px;
        width: 85px;
        margin-top: 0;
    `}

    ${breakpoint.breakLarge`
        height: 54px;
        width: 160px;
    `}
`;
