import axios from "axios";
import queryString from "query-string";
import { store, fetchAgentToken } from "@redux";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

// Helper to wait for `isLoading` to be `false`
const waitForLoadingToFinish = (): Promise<void> => {
    return new Promise((resolve) => {
        const checkLoading = () => {
            if (!store.getState().auth.isLoading) {
                resolve();
            } else {
                setTimeout(checkLoading, 50);
            }
        };
        checkLoading();
    });
};

// Helper to fetch and update the shared token
const ensureAgentToken = async () => {
    const state = store.getState();
    const agentToken = state.auth.auth?.data?.access_token;

    if (agentToken) {
        return agentToken;
    }

    if (!state.auth.isLoading) {
        await store.dispatch(fetchAgentToken());
    }

    await waitForLoadingToFinish();
    return store.getState().auth.auth?.data?.access_token || null;
};

// Request interceptor
axiosClient.interceptors.request.use(async (config) => {
    await waitForLoadingToFinish();
    const token = await ensureAgentToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 500 && !originalRequest.__isRetry) {
            originalRequest.__isRetry = true;
            console.log("500 Error: Retrying with a new token...");
            try {
                const newToken = await ensureAgentToken();
                if (newToken) {
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return await axiosClient(originalRequest);
                }
                return Promise.reject("Failed to refresh the token.");
            } catch (fetchError) {
                return Promise.reject(fetchError);
            }
        }

        console.error("Unhandled Error:", error);
        return Promise.reject(error);
    },
);

export default axiosClient;
