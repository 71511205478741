import { logger } from "@utilities";
import axios from "axios";
import queryString from "query-string";
import { logout, resetSavedCart, setSignIn, store } from "@redux";
import { normalPath } from "../Routes/index";
import { PATH_HOME } from "@routes";
import { ssPathname } from "../Utilities/storage";
import { authApi } from "./authApi";

const authClientV2 = axios.create({
    baseURL: process.env.REACT_APP_API_URL_V2,

    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },

    paramsSerializer: (params) => queryString.stringify(params),
});

authClientV2.interceptors.request.use((config) => {
    const signInToken = store.getState().signIn.signIn?.data.accessToken;
    config.headers.Authorization = `Bearer ${signInToken}`;
    return config;
});

authClientV2.interceptors.response.use(
    (response) => {
        // Pass through successful responses
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Handle 401 Unauthorized errors
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = store.getState().signIn.signIn?.data.refreshToken;
                const refreshResponse = await authApi.refresh({ refresh_token: refreshToken });
                const newAccessToken = refreshResponse.data.data.accessToken;
                store.dispatch(
                    setSignIn({
                        ...store.getState().signIn.signIn,
                        data: {
                            ...store.getState().signIn.signIn?.data,
                            accessToken: newAccessToken,
                        },
                    }),
                );
                originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                return authClientV2(originalRequest);
            } catch (refreshError) {
                const current = window.location.pathname;
                const isNormalRoute =
                    current === PATH_HOME || normalPath.some((item) => current.includes(item));

                if (isNormalRoute) ssPathname.setItem(current + window.location.search);
                store.dispatch(logout());
                store.dispatch(resetSavedCart());
                console.error("Token refresh failed:", refreshError);
                return Promise.reject(refreshError);
            }
        }

        if (error.response && ![404, 400].includes(error.response.status)) {
            logger.apiError(window.location.href, error.response);
        }

        throw error;
    },
);

export default authClientV2;
