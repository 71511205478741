import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Formik, FormikHelpers } from "formik";

import { BreadcrumbsCommon, MenuAccountComponent, PasswordComponent } from "@components";
import { AppModuleLayout } from "@layouts";
import { PATH_HOME, PATH_UPDATE_PASSWORD } from "@routes";
import { breakpoint } from "@utilities";
import { AppDispatch, openToast, toggleLoading } from "@redux";
import { authApi } from "@api";
import { passwordSchema } from "@validations";
import { IPasswordInfo } from "@interfaces";
import { DURATION_TIME } from "@constants";
import { SuccessToast } from "src/Components/toasts/SuccessToast";
import { ErrorToast } from "src/Components/toasts/ErrorToast";

export const UpdatePassword = () => {
    const dispatch = useDispatch<AppDispatch>();
    const breadcrumbsData = [
        {
            title: "Home",
            link: PATH_HOME,
        },
        {
            title: "Update My Password",
            link: PATH_UPDATE_PASSWORD,
        },
    ];

    const initialValues = {
        current_password: "",
        new_password: "",
        confirmPassword: "",
    };

    const handleSubmitForm = async (
        values: IPasswordInfo,
        { setSubmitting }: FormikHelpers<IPasswordInfo>,
    ) => {
        const currentPassword = values.current_password.trim();
        const newPassword = values.new_password.trim();
        const confirmPassword = values.confirmPassword.trim();

        if (newPassword !== confirmPassword) {
            dispatch(
                openToast({
                    open: true,
                    type: "error",
                    message: `Passwords must match.`,
                    autoHideDuration: DURATION_TIME,
                }),
            );
            return;
        }
        if (newPassword.length < 8) {
            dispatch(
                openToast({
                    open: true,
                    type: "error",
                    message: `Password is too short - should be 8 chars minimum.`,
                    autoHideDuration: DURATION_TIME,
                }),
            );
            return;
        }
        try {
            dispatch(toggleLoading(true));
            const res = await authApi.changePass({
                new_password: newPassword,
                current_password: currentPassword,
            });
            if (res.data.code === 200) {
                SuccessToast({ Message: "Password changed successfully" });
            }
        } catch (err: any) {
            if (err) {
                ErrorToast({ Message: err.response.data.title });
            }
        } finally {
            setSubmitting(false);
            dispatch(toggleLoading(false));
        }
    };

    return (
        <AppModuleLayout>
            <StyledMyAccountPage>
                <div className="wrapper">
                    <div className="breadCrumbs">
                        <BreadcrumbsCommon data={breadcrumbsData} />
                    </div>
                    <div className="menu">
                        <MenuAccountComponent />
                    </div>
                    <div className="my_account">
                        <p className="heading1">Update Password</p>
                        <FormContainer>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={passwordSchema}
                                onSubmit={handleSubmitForm}
                            >
                                {({
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => {
                                    return (
                                        <PasswordComponent
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            handleSubmit={handleSubmit}
                                            isSubmitting={isSubmitting}
                                        />
                                    );
                                }}
                            </Formik>
                        </FormContainer>
                    </div>
                </div>
            </StyledMyAccountPage>
        </AppModuleLayout>
    );
};

const StyledMyAccountPage = styled.div`
    background-color: #f2f2f2;
    min-height: calc(100vh - 95px);
    ${breakpoint.breakOnlyMobile`
    padding:24px 20px;
    `}

    .wrapper {
        display: block;
        margin: 0 auto;
        position: relative;

        ${breakpoint.breakTablet`
        display: flex;
        width: ${(p) => p.theme.widths.wide_700};
        margin-top: 81px;
        padding-bottom: 20px;
    `}

        ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}

    .breadCrumbs {
            position: absolute;
            bottom: calc(100% + 30px);
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }

        .menu {
            display: none;

            ${breakpoint.breakTablet`
            display: block;
        `}
        }
    }

    .my_account {
        padding: 24px 16px;
        background-color: #f2f2f2;
        position: relative;
        top: 11px;
        margin-bottom: 111px;
        ${breakpoint.breakOnlyMobile`
         background-color:#fff;
         padding: 30px 30px;
       `}

        ${breakpoint.breakTablet`
        flex: 1;
        padding: 0;
    `}

        .heading1 {
            font-size: 28px;
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 26px;
            margin-top: -10px;
            ${breakpoint.breakTablet`
            display: block;
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
        `}
        }
    }
`;

const FormContainer = styled.div`
    background-color: #fff;
    padding: 32px 32px 45px 32px;
    ${breakpoint.breakOnlyMobile`
    background-color: transparent;
    padding: 0px;
      
    `}
`;
