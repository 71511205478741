import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { AppDispatch, clearSharedToken, logout, resetSavedCart } from "@redux";
import { IconLogOut } from "@components";
import { IItemMenuHeader, IUserProfile } from "@interfaces";
import { ssPathname } from "@utilities";
import { useEffect, useState } from "react";
import { authApi } from "@api";

interface IProps {
    listMenuItems: IItemMenuHeader[];
}

export const MenuHeaderComponent = (props: IProps) => {
    const { listMenuItems } = props;
    const dispatch = useDispatch<AppDispatch>();
    const [userProfileData, setUserProfileData] = useState<IUserProfile | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userProfile = await authApi.userProfile();
                setUserProfileData(userProfile.data.data);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserProfile();
    }, []);

    const handleLogout = () => {
        ssPathname.removeItem();
        dispatch(logout());
        dispatch(clearSharedToken());
        dispatch(resetSavedCart());
    };

    return (
        <Container>
            {loading ? (
                <div className="linkItem" onClick={handleLogout}>
                    <div className="itemWrapper">
                        <span className="linkText"> ...Loading</span>
                    </div>
                </div>
            ) : (
                <>
                    {listMenuItems.map((menuItem, index) => {
                        const Icon = menuItem.icon;
                        if (
                            userProfileData?.sso_type !== "email" &&
                            menuItem.title === "Update Password"
                        ) {
                            return null;
                        }
                        return (
                            <Link className="linkItem" to={menuItem.link} key={index}>
                                <div className="itemWrapper">
                                    <span className="icon">
                                        {/* <IconHeart /> */}
                                        <Icon />
                                    </span>
                                    <span className="linkText">{menuItem.title}</span>
                                </div>
                            </Link>
                        );
                    })}
                    <div className="linkItem logOut" onClick={handleLogout}>
                        <div className="itemWrapper">
                            <span className="icon">
                                <IconLogOut />
                            </span>
                            <span className="linkText">Logout</span>
                        </div>
                    </div>
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    width: 255px;
    background-color: #fff;
    position: absolute;
    top: calc(100% + 20px);
    right: 0;
    box-shadow: 4px 4px 45px rgba(0, 0, 0, 0.08);
    border: 1px solid ${(p) => p.theme.colors.gray_5};
    padding: 0 30px;

    .linkItem {
        cursor: pointer;

        .itemWrapper {
            display: flex;
            align-items: center;
            padding: 24px 0;
            border-bottom: 1px solid ${(p) => p.theme.colors.gray_5};
        }

        .linkText {
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            color: #000;
        }

        .icon {
            margin-right: 16px;

            svg {
                width: 20px;

                path {
                    stroke: ${(p) => p.theme.colors.gray_1} !important;
                }
            }
        }

        &:last-child {
            .itemWrapper {
                border-bottom: none;
            }
        }
    }

    .logOut {
        color: ${(p) => p.theme.colors.gray_3};

        .itemWrapper {
            .icon svg {
                path {
                    stroke: ${(p) => p.theme.colors.red} !important;
                }
            }

            .linkText {
                color: ${(p) => p.theme.colors.red};
            }
        }
    }
`;
