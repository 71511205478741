import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CartItemComponent, IncreaseCartComponent } from "@components";
import { PATH_DETAIL_TOUR } from "@routes";
import { IFareCart, IPickupLocationFare, OptionalField } from "@interfaces";
import {
    changeQty,
    openToast,
    selectCart,
    useAppSelector,
    changePickupLocation,
    AppDispatch,
} from "@redux";
import { DURATION_TIME } from "@constants";
import { useDispatch } from "react-redux";
import Dropdown from "./CustomDropdown"; // Import the custom dropdown
import { OptionalFieldModule } from "./OptionalFieldModule";
import { breakpoint } from "@utilities";

interface IPropsCartItem {
    item: IFareCart;
    handleDeletePopUp: (cartDeleteId: string | number) => void;
}

const CartItem = (props: IPropsCartItem) => {
    const { item, handleDeletePopUp } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const carts = useAppSelector(selectCart).carts;

    const totalPrice = item.quantity * item.rrp;
    const cartItemIndex = carts.findIndex((cartItem) => cartItem.id === item.id);
    const pickupLocationField: OptionalField = {
        value: "",
        apiName: "",
        mandatory: false,
        optionalFieldId: "pickupLocation",
        options: [],
        title: "Pick Up Location",
        type: "select",
    };

    const handleClick = () => {
        navigate(`${PATH_DETAIL_TOUR}/${item.tour.productId}`);
    };

    const increaseQty = useCallback(() => {
        if (item.quantity < item.available) {
            dispatch(changeQty({ cartItemIndex, quantity: item.quantity + 1 }));
        } else {
            dispatch(
                openToast({
                    open: true,
                    type: "error",
                    message: `Your request has exceeded the quantity of ${item.fareName} available. Please choose another date or fare type.`,
                    autoHideDuration: DURATION_TIME,
                }),
            );
        }
    }, [item.quantity, item.available]);

    const decreaseQty = useCallback(() => {
        if (cartItemIndex >= 0) {
            if (item.quantity === 1) {
                handleDelete();
            } else {
                dispatch(changeQty({ cartItemIndex, quantity: item.quantity - 1 }));
            }
        }
    }, [item.quantity]);

    const handleDelete = () => {
        handleDeletePopUp(item.id);
    };

    const handlePickupLocationChange = (value: string) => {
        const pickupLocation = item.pickupLocations?.find((pickup) => pickup.PickupID === value);
        dispatch(changePickupLocation({ itemId: item.id, pickupLocation }));
    };

    return (
        <>
            <CartItemComponent cart={item} onClickDeleteBtn={handleDelete} onClick={handleClick}>
                <IncreaseCartComponent
                    quantity={item.quantity}
                    handleIncrease={increaseQty}
                    handleDecrease={decreaseQty}
                    totalPrice={totalPrice}
                />
            </CartItemComponent>
            <CartItemDetails>
                <OptionalFieldsRow>
                    {item.optionalFields?.map((optionalField: OptionalField, index: number) => (
                        <OptionalFieldContainer key={index}>
                            {optionalField.mandatory && (
                                <>
                                    <FieldLabel htmlFor={`input_${item.id}_${index}`}>
                                        {optionalField.title} <span>*</span>
                                    </FieldLabel>
                                    <OptionalFieldModule
                                        optionalField={optionalField}
                                        itemId={item.id}
                                    />
                                </>
                            )}
                        </OptionalFieldContainer>
                    ))}
                </OptionalFieldsRow>
                {item.pickupLocations && item.pickupLocations.length > 0 && (
                    <>
                        <PickupFieldLabel htmlFor={`input-pickup`}>
                            {pickupLocationField.title} <span>*</span>
                        </PickupFieldLabel>
                        <Dropdown
                            options={item.pickupLocations.map((location: IPickupLocationFare) => ({
                                label: `${location.PickupTime} ${location.PickupLocation}`,
                                value: location.PickupID || "",
                            }))}
                            value={item.pickupLocation?.PickupID || ""}
                            onChange={handlePickupLocationChange}
                            placeholder="Select Pickup Location"
                        />
                    </>
                )}
            </CartItemDetails>
        </>
    );
};

const CartItemDetails = styled.div`
    display: flex;
    flex-wrap: wrap; // Allow wrapping to the next line if necessary
    gap: 16px; // Space between fields
    background: #fff;
    padding: 20px 16px;
    margin-bottom: 24px;

    ${breakpoint.breakOnlyMobile`
        flex-direction: column; 
        gap: 8px;
    `}
`;

const OptionalFieldsRow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* This creates 2 equal-width columns */
    gap: 16px; /* Adds space between columns */
    width: 100%;
    margin-top: 10px;
    margin-bottom: -12px;
    ${breakpoint.breakOnlyMobile`
            grid-template-columns: repeat(1, 1fr); /* This creates 2 equal-width columns */

    `}
`;

const OptionalFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
    min-width: 200px;
    width: 100%; // Ensures that the container stretches to full width

    ${breakpoint.breakOnlyMobile`
        flex-direction: column; // Stack in a column on mobile
        width: 100%;
    `}
`;

const FieldLabel = styled.label`
    font-weight: bold;
    font-size: 16px;
    color: #090a18;
    margin-bottom: 8px;

    span {
        color: red;
    }
`;

const PickupFieldLabel = styled.label`
    font-weight: bold;
    font-size: 16px;
    color: #090a18;
    margin-bottom: -8px;
    span {
        color: red;
    }
`;

export default CartItem;
