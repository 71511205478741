import { authApi } from "@api";
import { LinkAgentComponent } from "@components";
import { IAgentInfoV2, IAgentStatusResponse } from "@interfaces";
import { agentloginSchema } from "@validations";
import { Formik } from "formik";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { SuccessToast } from "src/Components/toasts/SuccessToast";

export const AgentLoginForm = ({
    initialValues,
    onLogin,
}: {
    initialValues: IAgentInfoV2;
    onLogin: (payload: IAgentStatusResponse) => void;
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={agentloginSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const res = await authApi.agentLoginAdd(values);
                    if (res.data.code === 201) {
                        SuccessToast({ Message: "Agent Linked Successfully" });
                        onLogin(res.data);
                    } else if (res.data.code === 400) {
                        ErrorToast({
                            Message: `Error logging in agent: ${res.data.data.username?.[0]}`,
                        });
                    }
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <LinkAgentComponent
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                />
            )}
        </Formik>
    );
};
