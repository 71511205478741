import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppleLogin, IAuthLogin, IGoogleLogin, ILogin } from "@interfaces";
import { RootState } from ".";
import { authApi } from "@api";

export const appleLoginAction = createAsyncThunk<IAuthLogin, IAppleLogin>(
    "auth/appleLogin",
    async (values: IAppleLogin, { rejectWithValue }) => {
        try {
            const res = await authApi.appleLogin(values);
            return res.data as IAuthLogin;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const googleLoginAction = createAsyncThunk<IAuthLogin, IGoogleLogin>(
    "auth/googleLogin",
    async (values: IGoogleLogin, { rejectWithValue }) => {
        try {
            const res = await authApi.googleLogin(values);
            return res.data as IAuthLogin;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);
export const loginV2 = createAsyncThunk<IAuthLogin, ILogin>(
    "auth/login",
    async (values: ILogin, { rejectWithValue }) => {
        try {
            const res = await authApi.loginV2(values);
            return res.data as IAuthLogin;
        } catch (err: any) {
            return rejectWithValue(err.response.data);
        }
    },
);

interface IState {
    signIn: IAuthLogin | null;
    isLoading: boolean;
    error: string;
    tokenInfoSignIn: string;
}

const initialState: IState = {
    signIn: null,
    isLoading: false,
    error: "",
    tokenInfoSignIn: "",
};

const loginSlice = createSlice({
    name: "signIn",
    initialState: initialState,
    reducers: {
        logout: () => {
            return initialState;
        },
        setTokenInfoSignIn: (state, action: PayloadAction<string>) => {
            state.tokenInfoSignIn = action.payload;
        },
        setSignIn: (state, action: PayloadAction<IAuthLogin>) => {
            state.signIn = action.payload;
        },
    },
    extraReducers: (builder) => {
        // Handle googleLoginAction.fulfilled
        builder.addCase(
            googleLoginAction.fulfilled,
            (state, { payload }: PayloadAction<IAuthLogin>) => {
                if (payload?.data.accessToken) {
                    state.signIn = payload;
                }
                state.isLoading = false;
            },
        );

        // Handle appleLoginAction.fulfilled
        builder.addCase(
            appleLoginAction.fulfilled,
            (state, { payload }: PayloadAction<IAuthLogin>) => {
                if (payload?.data.accessToken) {
                    state.signIn = payload;
                }
                state.isLoading = false;
            },
        );

        // Handle loginV2.fulfilled
        builder.addCase(loginV2.fulfilled, (state, { payload }: PayloadAction<IAuthLogin>) => {
            if (payload.data.accessToken) {
                state.signIn = payload;
            }
            state.isLoading = false;
        });

        builder.addCase(appleLoginAction.pending, (state) => {
            state.isLoading = true;
        });
        // Handle googleLoginAction.pending
        builder.addCase(googleLoginAction.pending, (state) => {
            state.isLoading = true;
        });

        // Handle loginV2.pending
        builder.addCase(loginV2.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(appleLoginAction.rejected, (state) => {
            state.signIn = null;
            state.isLoading = false;
        });
        // Handle googleLoginAction.rejected
        builder.addCase(googleLoginAction.rejected, (state) => {
            state.signIn = null;
            state.isLoading = false;
        });

        // Handle loginV2.rejected
        builder.addCase(loginV2.rejected, (state) => {
            state.signIn = null;
            state.isLoading = false;
        });
    },
});

export const selectSignIn = (state: RootState) => state.signIn;

export const { logout, setTokenInfoSignIn, setSignIn } = loginSlice.actions;

export default loginSlice.reducer;
