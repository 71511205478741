import styled from "styled-components";

import { breakpoint } from "@utilities";
// import { StyledButton } from "@components";

interface IProps {
    color?: string | string[];
    disabled?: boolean;

}

function backgroundColor(props: IProps) {
    const baseColor = "#31b4b9"; 
    if (props.disabled) {
        return "#BDBDBD"; 
    }
    if (Array.isArray(props.color) && props.color.length > 1) {
        const gradientColors = props.color.join(", ");
        return `linear-gradient(90deg, ${gradientColors})`;
    } else if (Array.isArray(props.color) && props.color.length === 1) {
        return props.color[0];
    } else if (props.color && typeof props.color === "string") {
        return props.color;
    }

    return baseColor;
}


export const StyledTourDetail = styled.div<{
        statusApp?:boolean,
}>`
    width: ${(props) => props.theme.widths.wide_700};
    margin: 0 auto;
    background-color: #fff;

    ${breakpoint.breakIpadPro`
        width: ${(props) => props.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(props) => props.theme.widths.wide_1110};
    `}

    .noFare {
        padding: 10px 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
    }

    .heading {
        height: 70px;
        font-weight: 700;
        font-size: 15px;
        background-color: ${(props) => props.theme.colors.black};
        color: #fff;
        text-align: center;
        padding: 40px;

        ${breakpoint.breakLarge`
            height: 100%;
            font-size: 28px;
        `}
    }

    .content {
        display: "block";
    }

    .content {
        table {
            width: 100%;
        }

        .listBtn {
            display: flex;
        }

        thead {
            font-weight: bold;

            td {
                ${breakpoint.breakLarge`
                    font-size: 20px;
                `}
            }
        }

        td {
            font-weight: 400;
            line-height: 15px;
            border-bottom: ${(props) => `1px solid ${props.theme.colors.gray_5}`};
            font-size: 10px;
            padding: 30px 10px;
            background-color: #fff;

            ${breakpoint.breakLarge`
                font-size: 16px;
                padding: 47px 10px;
                line-height: 25.6px;
            `}

            ${breakpoint.breakIpadPro`
                font-size: 15px;
            `}

            &:first-child {
                padding: 20px 10px 20px 33px;

                @media (min-width: ${p => p.theme.breakPoints.breakLarge}) {
                    font-size: 15px;
                    padding: 47px 10px 47px 33px;
                }
            }

            &:last-child {
                padding: 47px 33px 47px 10px;
                padding-right:${(props)=> props?.statusApp ? '0px': '33px'};
            }

            & > div {
                display: flex;
                align-items: center;

                .priceIcon {
                    margin-left: 5px;
                    width: 15px;

                    ${breakpoint.breakLarge`
                        margin-left: 5px;
                        width: auto;
                    `}
                }
            }
        }

        td.fareType > div {
            max-width: 140px;
        }

        tr {
            ${breakpoint.breakLarge`
                height: 100px;
                font-size: 16px;
            `}
        }
    }
`;

export const CheckAvailability = styled.div`
    font-size: 9px;
    margin-left: 10px;
    white-space: nowrap;
    padding: 0 10px;
    flex: 1;

    button {
        margin-top: 0;
        height: 40px;
    }

    ${breakpoint.breakIpadPro`
        font-size: 12px;
    `}

    ${breakpoint.breakLarge`
        button {
            height: 54px;
        }

        font-size: 14px;
    `}
`;

const StyledButton = styled.button<IProps>`
    border: none;
    width: 100%;
    height: 54px;
    background: ${props => backgroundColor(props)};
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-top: 30px;
    border-radius: 0;

    ${breakpoint.breakTablet`
        font-size: 12px;
    `}

    ${breakpoint.breakIpadPro`
        font-size: 16px;
    `}
`;

export const AddToCart = styled(StyledButton)`
    font-size: 9px;
    height: 40px;
    margin: 0 2.5px;
    white-space: nowrap;
    padding: 0 10px;
    flex: 1;
    cursor: pointer;
    border-radius:4px;

    ${breakpoint.breakIpadPro`
        font-size: 12px;
        margin: 0 5px;
    `}

    ${breakpoint.breakLarge`
        height: 54px;
        font-size: 14px;
        margin: 0 8px;
    `}
`;
