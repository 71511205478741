import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import { IAuthenticationCode, IHandleSubmit } from "@interfaces";
import { StyledBorderButton, StyledButton, StyledError, StyledForm, StyledLink } from "@components";
import { IHandleChange, IHandleBlur } from "@interfaces";
import { PATH_LOGIN } from "@routes";
import { NavLink } from "react-router-dom";
import { authApi } from "@api";
import { SuccessToast } from "../toasts/SuccessToast";
import { ErrorToast } from "../toasts/ErrorToast";

interface AuthenticationFormProps {
    values: IAuthenticationCode;
    handleChange: IHandleChange;
    handleBlur: IHandleBlur;
    handleSubmit: IHandleSubmit;
    isSubmitting: boolean;
    constantText: string;
    constantContact: string;
    enteredEmail: string;
    errors: { [key: string]: string };
    touched: { [key: string]: boolean };
}

export const AuthenticationComponent = (props: AuthenticationFormProps) => {
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        constantText,
        constantContact,
        enteredEmail,
        errors,
        touched,
    } = props;
    const navigate = useNavigate();
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const [countdown, setCountdown] = useState(60); // 1 minute countdown

    useEffect(() => {
        // Countdown timer logic
        if (countdown > 0) {
            const timer = setInterval(() => setCountdown(countdown - 1), 1000);
            return () => clearInterval(timer); // Clean up the interval on unmount
        }
    }, [countdown]);

    const handleBackToLogin = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        navigate(PATH_LOGIN);
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("Text").slice(0, 5); // Limit to 5 characters
    
        if (/^\d{5}$/.test(pasteData)) { // Ensure pasted content is a 5-digit number
            const codeArray = pasteData.split("");
            handleChange({ target: { name: "otp", value: pasteData } } as any);
    
            // Set each input's value and move focus
            codeArray.forEach((char, index) => {
                inputRefs.current[index]!.value = char;
                if (inputRefs.current[index + 1]) {
                    inputRefs.current[index + 1]?.focus();
                }
            });
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace") {
            // Clear current input value
            const codeArray = values.otp.split("");
            codeArray[index] = "";
            const newCode = codeArray.join("");

            handleChange({ target: { name: "otp", value: newCode } } as any);

            // Move focus to the previous input field if available
            if (index > 0) {
                inputRefs.current[index - 1]?.focus();
            }
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;

        // Accept only a single-digit numeric value
        if (/^\d$/.test(value)) {
            const codeArray = values.otp.split("");
            codeArray[index] = value;
            const newCode = codeArray.join("");

            // Update the code in values
            handleChange({ target: { name: "otp", value: newCode } } as any);

            // Move focus to the next input field if available
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }
        } else {
            e.target.value = "";
        }
    };

    const handleResendCode = async (values: { email: string }) => {
        const emailData = { email: values.email };
        try {
            const res = await authApi.forgotPassEmail(emailData);
            SuccessToast({ Message: res.data.message });
        } catch (err: any) {
            ErrorToast({ Message: err.data?.message || "An error occurred" });
        } finally {
            setCountdown(60);
        }
    };

    return (
        <>
            <StyledWrapper onSubmit={handleSubmit}>
                <h2 className="heading">Enter Authentication Code</h2>
                <p className="subHeading">
                    Enter the 5-digit that we have sent via email to
                    <span> {enteredEmail}</span>
                </p>
                <StyledCodeContainer>
                    {[...Array(5)].map((_, index) => (
                        <StyledCodeInput
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)}
                            type="text"
                            inputMode="numeric" // Mobile-friendly numeric keypad
                            pattern="\d*" // Accept digits only
                            maxLength={1} // Limit input to one digit per field
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onBlur={handleBlur}
                            onPaste={index === 0 ? handlePaste : undefined}
                            value={values.otp[index] || ""}
                        />
                    ))}
                </StyledCodeContainer>
                {errors.otp && touched.otp && (
                    <StyledError className="error">{errors.otp}</StyledError>
                )}
                <StyledButton
                    borderRadius="4px"
                    color={["#436CB2 0%", "#28B7C3 100%"]}
                    className="send_link"
                    type="submit"
                >
                    Continue
                </StyledButton>
                {/* todo need to add counter logic when integrating forgot password flow */}
                <StyledLine>
                    Didn’t receive a code?
                    {countdown > 0 ? (
                        ` Resend Code in ${countdown}s`
                    ) : (
                        <ResendButton
                            type="button"
                            onClick={() => handleResendCode({ email: enteredEmail })}
                        >
                            {" "}
                            Resend Code
                        </ResendButton>
                    )}
                </StyledLine>
                <StyledBorderButton onClick={handleBackToLogin}>Back to Login</StyledBorderButton>
                <StyledLink>
                    Don't have an account? <NavLink to="/signup"> Sign up</NavLink>, to start
                    selling today.
                </StyledLink>
                <StyledLine className="contact">{constantContact}</StyledLine>
                <StyledLine>{constantText}</StyledLine>
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled(StyledForm)`
    .heading {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 18px;
    }

    .subHeading {
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: ${(props) => props.theme.colors.gray_2};
    }
    .subHeading span {
        font-weight: 700 !important;
    }
    .send_link {
        margin-top: 30px;
    }
    .error {
        text-align: center;
    }
`;

const StyledLine = styled.div`
    font-weight: 100;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray_1};
    margin: 10px 0px;
`;

const StyledCodeContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
`;

const StyledCodeInput = styled.input`
    width: 50px;
    height: 50px;
    margin: 20px 0px 0px 0px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray_2};
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    transition: border-color 0.2s ease-in-out; // Smooth transition for border color change

    &:focus {
        outline: none;
        border: 2px solid #31b4b9;
    }
`;

const ResendButton = styled.button`
    background: none;
    border: none;
    color: #31b4b9;
    text-decoration: underline;
    font-size: inherit;
    cursor: pointer;
    padding: 0;
    margin-left: 5px;

    &:hover {
        color: #278f93; // Optional: A darker shade of blue on hover
    }
`;
