import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuth } from "@interfaces";
import { RootState } from ".";
import { authApi } from "@api";

export const fetchAgentToken = createAsyncThunk<IAuth, void>(
    "auth/fetchAgentToken",
    async (_, { rejectWithValue }) => {
        try {
            const res = await authApi.agentInfo();
            return res.data as IAuth;
        } catch (err: any) {
            return rejectWithValue(err.response?.data || "Error fetching agent token");
        }
    },
);

interface IState {
    auth: IAuth | null;
    isLoading: boolean;
    error: string | null;
    tokenInfoAuth: string;
}

const initialState: IState = {
    auth: null,
    isLoading: false,
    error: null,
    tokenInfoAuth: "",
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        clearAgentToken: () => {
            return initialState;
        },
        setTokenInfoAuth: (state, action: PayloadAction<string>) => {
            state.tokenInfoAuth = action.payload;
        },
        setAuth: (state, action: PayloadAction<IAuth>) => {
            state.auth = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAgentToken.fulfilled, (state, action: PayloadAction<IAuth>) => {
                state.auth = action.payload;
                state.isLoading = false;
            })
            .addCase(fetchAgentToken.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAgentToken.rejected, (state, action: PayloadAction<any>) => {
                state.error = action.payload || "Failed to fetch shared token";
                state.isLoading = false;
            });
    },
});

export const selectAuth = (state: RootState) => state.auth;

export const { clearAgentToken, setTokenInfoAuth, setAuth } = authSlice.actions;

export default authSlice.reducer;
