import styled from "styled-components";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ClipLoaderCommon, ConfirmComponent, ProcessOrderComponent } from "@components";
import { CartConstants, DURATION_TIME } from "@constants";
import { CartModule, CheckoutModule, OrderSuccessModule, PaymentModule } from "@module";
import {
    setStep,
    setCurrentStep,
    useAppSelector,
    selectCart,
    openToast,
    removeCart,
    changeQty,
    AppDispatch,
} from "@redux";
import { AppModuleLayout } from "@layouts";
import { breakpoint, diffDateCart } from "@utilities";
import { ICheckAvailApi, IFareAvail } from "@interfaces";
import { toursApi } from "@api";
import { NavLink } from "react-router-dom";

export const Cart = () => {
    //page hook
    const dispatch = useDispatch<AppDispatch>();
    //redux state
    const { step, currentStep, carts } = useAppSelector(selectCart);
    //page state
    const [currentPoint, setCurrentPoint] = useState(step);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
    const [cartDeleteId, setCartDeleteId] = useState<string | number>("");
    //page variable
    const { CHECKOUT, ORDER_SUCCESS, PAYMENT } = CartConstants;

    //scroll on top
    useEffect(() => {
        setCurrentPoint(step);
        scrollToTop();
    }, [step]);

    // WHAT: empty cart
    useEffect(() => {
        if (carts.length === 0) {
            dispatch(
                openToast({
                    type: "error",
                    message: `Cart is empty`,
                    autoHideDuration: DURATION_TIME,
                }),
            );
            setIsLoading(false);
        }
    }, [carts.length]);

    // WHAT: Check CartItem còn thỏa mãn không để hiện thị Không
    useEffect(() => {
        // WHAT: Check Date khách đặt so với ngày hiện tại . Nếu nhỏ hơn thông báo ra ngày đó đã hết hạn
        carts.forEach((cartItem, index) => {
            // chech cart item nào có date hết hạn thì remove
            if (diffDateCart(new Date(Date.now()), cartItem.date) < 0) {
                dispatch(removeCart(cartItem.id));
                dispatch(
                    openToast({
                        type: "error",
                        autoHideDuration: DURATION_TIME,
                        message: `${cartItem.fareName} has expired. Please select new items and add to cart.`,
                    }),
                );
            } else {
                // check Availability
                const checkAvailability: IFareAvail = {
                    bookingTime: cartItem.BookingTimeID,
                    from: new Date(cartItem.date),
                    to: new Date(cartItem.date),
                    fareName: cartItem.fareName,
                    productPricesDetailsId: cartItem.productPricesDetailsId,
                };
                const payload: ICheckAvailApi = {
                    checkAvailability: checkAvailability,
                    cart: cartItem,
                    index: index,
                };
                checkAvailabilityRangeApi(payload);
            }
        });
    }, []);

    //handle check availability
    const checkAvailabilityRangeApi = async (payload: ICheckAvailApi) => {
        try {
            const res = await toursApi.checkAvail(payload.checkAvailability);
            // WHY: VÌ đây mình gọi lấy data từng ngày để check lên data chả về chỉ có 1 phần tử
            const data = res.data[0];
            const date = new Date(payload.cart.date);
            // nếu NumAvailable > 0 thì set lại quantity = NumAvailable
            if (data.NumAvailable > 0) {
                if (data.NumAvailable < payload.cart.quantity) {
                    dispatch(
                        changeQty({
                            cartItemIndex: payload.index,
                            quantity: data.NumAvailable,
                            numAvailable: data.NumAvailable,
                        }),
                    );
                    dispatch(
                        openToast({
                            type: "error",
                            message: `Tickets on ${date} only have ${data.NumAvailable} places available, this is not enough for the number of ${payload.cart.quantity} tickets you booked. Please contact support@freelancetravel.com for assistance.`,
                            autoHideDuration: DURATION_TIME,
                        }),
                    );
                }
            } else {
                dispatch(removeCart(payload.cart.id));
                dispatch(
                    openToast({
                        type: "error",
                        autoHideDuration: DURATION_TIME,
                        message: `${payload.cart.fareName} unavailable.`,
                    }),
                );
            }
        } catch (error) {
            console.error(error);
            dispatch(removeCart(payload.cart.id));
            dispatch(
                openToast({
                    type: "error",
                    autoHideDuration: DURATION_TIME,
                    message: `${payload.cart.fareName} faulty. Please select a different fare or contact support@freelancetravel.com.`,
                }),
            );
        } finally {
            setIsLoading(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const moveTo = (step: number) => {
        dispatch(setStep(step));
        if (step === currentStep + 1) {
            dispatch(setCurrentStep(step));
        }
    };

    const setLoading = (isLoad: boolean) => {
        setIsLoading(isLoad);
    };

    const renderComponent = () => {
        switch (step) {
            case CHECKOUT:
                return <CheckoutModule moveTo={moveTo} handleDeletePopUp={handleDeletePopUp} />;
            case ORDER_SUCCESS:
                return <OrderSuccessModule />;
            case PAYMENT:
                return <PaymentModule setLoading={setLoading} moveTo={moveTo} />;
            default:
                return <CartModule moveTo={moveTo} handleDeletePopUp={handleDeletePopUp} />;
        }
    };

    const handleHeaderBack = () => {
        if (step > 1) {
            dispatch(setStep(step - 1));
            return;
        }
        window.history.back();
    };

    const handleDeletePopUp = (cartDeleteId?: string | number) => {
        setIsShowPopup(!isShowPopup);
        setCartDeleteId(cartDeleteId || "");
    };

    const handleDeleteProduct = () => {
        handleDeletePopUp();
        if (cartDeleteId !== "") dispatch(removeCart(cartDeleteId));
    };

    return (
        <AppModuleLayout onBack={handleHeaderBack}>
            <ProcessOrderComponent currentPoint={currentPoint} />
            <Main>
                {carts.length === 0 ? (
                    <Container className="wrapper">
                        <Content>
                            <img
                                width={65}
                                height={65}
                                src="/images/cart/emptyCart.svg"
                                alt="emptyCartLogo"
                            />
                            <p>There are no items in the Cart</p>
                            <NavLink to="/">
                                <Button>Continue Browsing</Button>
                            </NavLink>
                        </Content>
                    </Container>
                ) : (
                    renderComponent()
                )}
                {isLoading && (
                    <div className="clipLoader">
                        <div className="overlay"></div>
                        <ClipLoaderCommon color="#31b4b9" margin={20} size={80} />
                    </div>
                )}
                <ConfirmComponent
                    isShowPopup={isShowPopup}
                    onClickOverlay={handleDeletePopUp}
                    onClickBtnYes={handleDeleteProduct}
                    onClickBtnNo={handleDeletePopUp}
                >
                    Are you sure you want to delete this fare?
                </ConfirmComponent>
            </Main>
        </AppModuleLayout>
    );
};

const Main = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
    ${breakpoint.breakTablet`
        padding-bottom: 30px;
    `}

    .clipLoader {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.2);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }

        .loader {
            z-index: 1;
        }
    }
`;

const Container = styled.div`
    background-color: white;
    display: flex;
    flex: 1;
    ${breakpoint.breakSmall`
        width: ${(p) => p.theme.widths.wide_700}
    `}

    ${breakpoint.breakTablet`
        width: ${(p) => p.theme.widths.wide_700};
    `}

    ${breakpoint.breakIpadPro`
        width: ${(p) => p.theme.widths.wide_900};
    `}

    ${breakpoint.breakLarge`
        width: ${(p) => p.theme.widths.wide_1110};
    `}
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 14px;
`;

const Button = styled.button`
    border: 1px solid #31b4b9;
    padding: 12px 24px;
    border-radius: 4px;
    color: #31b4b9;
    background: none;
    cursor: pointer;

    &:hover {
        background-color: #e0f7f8;
    }
`;
