import { IAuthLogin, IGoogleLogin } from "@interfaces";
import { useGoogleLogin } from "@react-oauth/google";
import { AppDispatch, googleLoginAction, toggleLoading } from "@redux";
import { useDispatch } from "react-redux";
import { ErrorToast } from "../toasts/ErrorToast";
import { StyledButton } from "@components";
import { useState } from "react";

const GoogleLoginButton = () => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            setIsSubmitting(true);
            dispatch(toggleLoading(true));
            try {
                const googleToken = tokenResponse.access_token;
                const values: IGoogleLogin = { google_access_token: googleToken };
                (await dispatch(googleLoginAction(values))) as {
                    payload: IAuthLogin;
                };
            } catch (error) {
                ErrorToast({ Message: `${error}` });
            } finally {
                setIsSubmitting(false);
                dispatch(toggleLoading(false));
            }
        },
        onError: (errorResponse) => {
            ErrorToast({ Message: `Google login error:${errorResponse}` });
            setIsSubmitting(false);
            dispatch(toggleLoading(false));
        },
    });

    return (
        <StyledButton
            borderRadius="4px"
            onClick={() => handleGoogleLogin()}
            disabled={isSubmitting}
            textColor="131214"
            type="button"
            color="#F4F6F7"
        >
            <img src="/images/Google.svg" alt="Google Logo" />
            Log In with Google
        </StyledButton>
    );
};

export default GoogleLoginButton;
