import { authApi } from "@api";
import { LinkAgentUpdateComponent } from "@components";
import { IAgentStatusResponse, IAgentInfoV2 } from "@interfaces";
import { agentloginSchema } from "@validations";
import { Formik } from "formik";
import { ErrorToast } from "src/Components/toasts/ErrorToast";
import { SuccessToast } from "src/Components/toasts/SuccessToast";

export const AgentLoginUpdateForm = ({
    username,
    onUpdate,
}: {
    username: string;
    onUpdate: (payload: IAgentStatusResponse) => void;
}) => {
    const initialValues: IAgentInfoV2 = { username, password: "" };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={agentloginSchema}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    const res = await authApi.agentLoginUpdate(values);
                    if (res.data.code === 200) {
                        SuccessToast({ Message: "Agent updated successfully" });
                        onUpdate(res.data);
                    } else if (res.data.code === 400) {
                        ErrorToast({
                            Message: `Error updating in agent: ${res.data.data.username?.[0]}`,
                        });
                    }
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <LinkAgentUpdateComponent
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                />
            )}
        </Formik>
    );
};