import { combineReducers } from "redux";
import { AnyAction, Reducer } from "@reduxjs/toolkit";
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";

// import authReducer from "./auth";
import authReducer from "./auth";
import sharedTokenReducer from "./sharedToken";
import signInReducer from "./signIn";
import splashReducer from "./splash";
import loadingReducer from "./loading";
import favoriteToursReducer from "./favoriteTours";
import externalDataReducer from "./externalData";
import searchCategoriesReducer from "./searchCategories";
import searchToursReducer from "./searchTours";
import tourReducer from "./tour";
import cartReducer from "./cart";
import statusAppReducer from "./statusApp";
import savedCartReducer from "./savedCarts";
import detailFareReducer from "./detailFare";
import bookingReducer from "./booking";
import toastReducer from "./toast";
import agentReducer from "./agent";
// const persistConfig = {
//     key: "root",
//     storage,
// };

// const authPersistedReducer = persistReducer(persistConfig, authReducer);
export * from "./auth";
export * from "./signIn";
export * from "./splash";
export * from "./loading";
export * from "./externalData";
export * from "./favoriteTours";
export * from "./searchCategories";
export * from "./searchTours";
export * from "./tour";
export * from "./cart";
export * from "./savedCarts";
export * from "./statusApp";
export * from "./detailFare";
export * from "./booking";
export * from "./toast";
export * from "./agent";
export * from "./sharedToken";

const appReducer = combineReducers({
    auth: authReducer,
    signIn: signInReducer,
    splash: splashReducer,
    loading: loadingReducer,
    favoriteTours: favoriteToursReducer,
    externalData: externalDataReducer,
    searchCategories: searchCategoriesReducer,
    searchTours: searchToursReducer,
    tour: tourReducer,
    cart: cartReducer,
    status: statusAppReducer,
    savedCart: savedCartReducer,
    detailFare: detailFareReducer,
    booking: bookingReducer,
    toast: toastReducer,
    agent: agentReducer,
    sharedToken : sharedTokenReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
    if (action.type === "RESET") {
        // reset state
        state = {} as RootState;
        // reset local storage
        localStorage.clear();
    }
    return appReducer(state, action);
};
export default rootReducer;
