import styled from "styled-components";
import { breakpoint } from "@utilities";

export const StyledDatePicker = styled.div`
    .MuiInputBase-root {
        font-size: 16px !important;
    }

    .MuiInput-underline:after,
    .MuiInput-underline:before {
        display: none !important;
    }

    .MuiInputBase-input {
        font-family: DM Sans;
        color: ${(props) => props.theme.colors.gray_3};

        ${breakpoint.breakTablet`
            font-size: 12px !important;
        `}

        ${breakpoint.breakIpadPro`
            font-size: 16px !important;
        `}
    }

    .MuiIconButton-label {
        width: 100%;

        ${breakpoint.breakTablet`
            width: 60%;
        `}

        ${breakpoint.breakIpadPro`
            width: 100%;
        `}
    }

    .MuiInputLabel-animated {
        font-size: 16px;
        font-weight: 100;
        color: ${(props) => props.theme.colors.gray_2};
    }

    .MuiInputLabel-root.Mui-focused {
        color: ${(p) => p.theme.colors.main};
        border-color: ${(p) => p.theme.colors.main};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${(p) => p.theme.colors.main};
    }
    .MuiSvgIcon-root {
        color: ${(p) => p.theme.colors.main}
    }
        
    .Mui-selected": {
        background-color:red, 
        color: #FFFFFF,
        border-radius: 50%, 
    },


    label + .MuiInput-formControl {
        margin-top: 24px;
    }
`;
