import axiosClient from "./axiosClient";
import {
    IForgotPassword,
    ILoginDemo,
    IAgentInfo,
    ILogin,
    ISignUp,
    IGoogleLogin,
    ILoginv2,
    IAppleLogin,
    IAuthenticationCode,
    IForgotPassEmail,
    IRefresh,
    IChangePassword,
} from "@interfaces";
import authClientV2 from "./authClientV2";

export const authApi = {
    login: (params: ILogin) => {
        const url = "/agentToken";
        return axiosClient.post(url, params);
    },
    agentLogin: (params: ILoginv2) => {
        const url = "/agentToken";
        return axiosClient.post(url, params);
    },
    loginV2: (params: ILogin) => {
        const url = "/user/login/email";
        return authClientV2.post(url, params);
    },
    refresh: (params: IRefresh) => {
        const url = "/user/token/access";
        return authClientV2.post(url, params);
    },
    signUp: (params: ISignUp) => {
        const url = "/user/signup/email/";
        return authClientV2.post(url, params);
    },
    googleLogin: (params: IGoogleLogin) => {
        const url = "/user/login/google";
        return authClientV2.post(url, params);
    },
    appleLogin: (params: IAppleLogin) => {
        const url = "/user/login/apple";
        return authClientV2.post(url, params);
    },
    loginDemo: (params: ILoginDemo) => {
        const url = "/auth/login";
        return axiosClient.post(url, params);
    },
    forgotPass: (params: IForgotPassword) => {
        const url = "/user/email/reset-password/";
        return authClientV2.post(url, params);
    },
    changePass: (params: IChangePassword) => {
        const url = "user/detail/change-password/";
        return authClientV2.post(url, params);
    },
    forgotPassEmail: (params: IForgotPassEmail) => {
        const url = "/user/login/email/forgot-password/";
        return authClientV2.post(url, params);
    },
    forgotPassCode: (params: IAuthenticationCode) => {
        const url = "user/email/otp/verify/";
        return authClientV2.post(url, params);
    },
    updateAgentInfo: (params: IAgentInfo) => {
        const url = "/updateAgentAccount";
        return axiosClient.post(url, params);
    },
    agentLoginAdd: (params: ILoginv2) => {
        const url = "/agent/token/integration/";
        return authClientV2.post(url, params);
    },
    agentLoginUpdate: (params: ILoginv2) => {
        const url = "/agent/token/integration/";
        return authClientV2.put(url, params);
    },
    agentInfo: () => {
        const url = "/agent/token/integration";
        return authClientV2.get(url);
    },
    userProfile: () => {
        const url = "/user/detail";
        return authClientV2.get(url);
    },
    unLinkAgent:()=>{
        const url = "/agent/token/integration/";
        return authClientV2.delete(url);
    }
};
